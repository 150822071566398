import { CommonModule } from "@angular/common";
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { NavigationEnd, Router, RouterModule } from "@angular/router";
import { ButtonModule } from "primeng/button";
import { Subscription, filter } from "rxjs";
import { ContactUsEventHandlerService } from "../services/contact-us.service";

@Component({
  selector: "app-header",
  standalone: true,
  imports: [CommonModule, ButtonModule, RouterModule],
  templateUrl: "./header.component.html",
  styleUrl: "./header.component.scss",
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() headerColor: "white" | "transparent" = "white";
  @Input() isSticky: boolean = false;

  @Output() contactUsClick = new EventEmitter<void>();
  @Output() joinWaitlistClick = new EventEmitter<void>();

  hideHeaderOnScrolling: boolean = false;
  isAlwaysVisible: boolean = false;
  isTopOfPageVisible: boolean = true;
  scrollTimeout!: ReturnType<typeof setTimeout> | undefined;

  private subscriptions = new Subscription();

  constructor(
    private router: Router,
    private contactUsEventHandlerService: ContactUsEventHandlerService,
  ) {}

  get elementsColor() {
    if (this.headerColor === "transparent") {
      return "white";
    } else {
      return "navy";
    }
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
    if (window.scrollY < window.innerHeight) {
      this.hideHeaderOnScrolling = false;
      this.isTopOfPageVisible = true;
    } else {
      if (this.scrollTimeout) {
        clearTimeout(this.scrollTimeout);
      }

      this.hideHeaderOnScrolling = true;
      this.isTopOfPageVisible = false;

      this.scrollTimeout = setTimeout(() => {
        this.hideHeaderOnScrolling = false;
      }, 500);
    }
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(() => {
          this.isAlwaysVisible =
            this.router.url === "/privacy-policy" ||
            this.router.url === "/terms-of-service";
        }),
    );
  }

  contactUsClickHandle(): void {
    if (this.router.url === "/") {
      this.contactUsEventHandlerService.contactUsEvent.emit();
    } else {
      this.router.navigateByUrl("/").then(() => {
        setTimeout(() => {
          this.contactUsEventHandlerService.contactUsEvent.emit();
        }, 0);
      });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
