import { Injectable } from "@angular/core";
import { ContactUs } from "@api/models";
import { ApiService } from "@api/services";
import { Action, State, StateContext } from "@ngxs/store";

export class SubmitContactUsAction {
  static readonly type = "[Marketing] Submit Contact Us";
  constructor(public request: ContactUs) {}
}

@State<object>({
  name: "ContactUs",
  defaults: {},
})
@Injectable()
export class ContactUsState {
  constructor(private api: ApiService) {}

  @Action(SubmitContactUsAction)
  submitContactUs(_ctx: StateContext<object>, action: SubmitContactUsAction) {
    this.api
      .contactUsPost({
        body: {
          firstName: action.request.firstName,
          lastName: action.request.lastName,
          email: action.request.email,
          organization: action.request.organization,
          phoneNumber: action.request.phoneNumber,
          profession: action.request.profession!,
          additionalInformation: action.request.additionalInformation,
        },
      })
      .subscribe();
  }
}
