/* tslint:disable */
/* eslint-disable */
export enum ProfessionType {
  EducationalInstitution = 'EducationalInstitution',
  HealthcareOrganization = 'HealthcareOrganization',
  Student = 'Student',
  HealthcareProfessional = 'HealthcareProfessional',
  RecruitmentAgency = 'RecruitmentAgency',
  Other = 'Other'
}
