<div
  class="header-{{ headerColor }}"
  [class.-translate-y-full]="hideHeaderOnScrolling"
  [class.hidden]="!isAlwaysVisible && isSticky && isTopOfPageVisible"
  [class.sticky]="isSticky"
  [class.border-grey-2]="isSticky"
  [class.header-box-shadow]="isSticky"
>
  <div class="px-4 sm:px-8 flex justify-between items-center">
    <a
      class="block sm:hidden cursor-pointer"
      routerLink="/"
      [class.hidden]="!isSticky"
    >
      <img src="assets/images/externi-small-logo-navy.svg" alt="Small Logo" />
    </a>
    <a
      class="hidden sm:block cursor-pointer"
      routerLink="/"
      [class.hidden]="isSticky"
    >
      <img
        src="assets/images/externi-logo-{{ elementsColor }}.svg"
        alt="Logo"
      />
    </a>
    <div class="flex flex-row items-center sm:gap-4" [class.gap-4]="isSticky">
      <div>
        <p-button
          id="header-contact-us-button"
          label="Contact Us"
          styleClass="header-button-semibold p-button-secondary-{{
            elementsColor
          }}"
          (onClick)="contactUsClickHandle()"
        ></p-button>
      </div>
      <div [class.hidden]="!isSticky" [class.sm:block]="!isSticky">
        <p-button
          id="header-join-waitlist-button"
          label="Request Demo"
          styleClass="header-button-semibold p-button-primary-{{
            elementsColor
          }}"
          (onClick)="joinWaitlistClick.emit()"
        ></p-button>
      </div>
    </div>
  </div>
</div>
