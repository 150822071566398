import { ProfessionType } from "@api/models";

type ProfessionRecord = Record<ProfessionType, string>;

export const ProfessionRadioButtonRecord: ProfessionRecord = {
  [ProfessionType.EducationalInstitution]: "Educational Institution",
  [ProfessionType.HealthcareOrganization]: "Healthcare Organization",
  [ProfessionType.Student]: "Student",
  [ProfessionType.HealthcareProfessional]: "Healthcare Professional",
  [ProfessionType.RecruitmentAgency]: "Recruitment/Staffing Agency",
  [ProfessionType.Other]: "Other",
};
